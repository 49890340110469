import React from 'react';

export function BurgerIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			width="32"
			height="32"
			fill="none"
			stroke="currentcolor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2">
			<path d="M4 8h24M4 16h24M4 24h24" />
		</svg>
	);
}
